import React, { useState } from 'react';
import { IoLeaf } from 'react-icons/io5';

import Dropdown from 'components/UI/dropdown/Dropdown';
import Button from 'components/UI/button/Button';
import Input from 'components/UI/input/Input';
import Modal from 'components/UI/modal/Modal';
import Box from 'components/UI/box/Box';

import { useStore } from 'context';
import config from 'config';
import utils from 'utils';

import styling from './Elements.module.scss';

const Item = ({ multiLanguageLabels, price, id, allergies, isVegetarian, isVegan }) => {
    // State
    const [{ amount, note, addToCartVisible }, setState] = useState({
        amount: 1,
        note: '',
        addToCartVisible: false
    });


    // Store
    const [{ currency, currentLanguage }, dispatch] = useStore();


    /**
     * Toggles the visibility of the add to cart modal.
     */
    const toggleAddToCartVisibility = () => {
        setState(prevState => ({ ...prevState, addToCartVisible: !prevState.addToCartVisible }));
    };


    /**
     * Handles input field changes.
     * @param value {string} the value that was entered
     */
    const changeHandler = ({ target: { value } }) => {
        setState(prevState => ({ ...prevState, note: value }));
    };


    /**
     * Handles dropdown field changes.
     * @param value {string} selected value
     */
    const dropdownChangeHandler = ({ value }) => {
        setState(prevState => ({ ...prevState, amount: +value }));
    };


    /**
     * Adds the current item to the cart.
     */
    const addItem = () => {
        dispatch({
            type: 'addItem',
            payload: {
                name: multiLanguageLabels?.[currentLanguage]?.name,
                price,
                id,
                note,
                amount
            }
        });

        setState({ amount: 1, note: '', addToCartVisible: false });
    };


    // Determine currency symbol
    const currencySymbol = config.currencies.find(x => x.value === currency)?.symbol || '';


    // Amount options
    const amountOptions = Array.from({ length: 10 }, (_, i) => ({ label: i + 1, value: i + 1 }));


    return (
        <>
            <div className={styling.item}>
                <div className={styling.information}>
                    <div className={styling.notice} hidden={!isVegetarian && !isVegan}>
                        <IoLeaf/>
                        <span hidden={!isVegetarian}>Vegetarian</span>
                        <span hidden={!isVegan}>Vegan</span>
                    </div>

                    <h4>{utils.getTranslation(multiLanguageLabels, 'name', currentLanguage)} <span>- {price}{currencySymbol}</span></h4>
                    <p>{utils.getTranslation(multiLanguageLabels, 'description', currentLanguage)}</p>
                </div>

                <div className={styling.actions}>
                    <button onClick={toggleAddToCartVisibility}>
                        {utils.getStaticLabel('menu_add_item_action_button', currentLanguage)}
                    </button>
                </div>
            </div>

            <div hidden={!addToCartVisible}>
                <Modal open={addToCartVisible}>
                    <h1>{utils.getStaticLabel('add_item_modal_title', currentLanguage)}</h1>

                    <Box type="warning" hidden={!allergies || !allergies.length} addBottomMargin>
                        <b>Allergy Information</b><br/>
                        Please be advised that the menu item, which you have selected, contains one or more of the following allergy
                        risks:<br/>
                        {(allergies || []).map(allergy => <span key={allergy}>• {allergy}<br/></span>)}
                    </Box>

                    <Input
                        value={note}
                        onChange={changeHandler}
                        label={utils.getStaticLabel('add_item_modal_notes_title', currentLanguage)}
                        placeholder={utils.getStaticLabel('add_item_modal_notes_placeholder', currentLanguage)}
                        isTextArea
                    />

                    <div className={styling.dropdown}>
                        <h6>{utils.getStaticLabel('add_item_modal_amount', currentLanguage)}</h6>

                        <Dropdown
                            options={amountOptions}
                            value={{ label: amount, value: amount }}
                            changeHandler={dropdownChangeHandler}
                        />
                    </div>

                    <div className={styling.controls}>
                        <Button type="clear" onClick={toggleAddToCartVisibility}>
                            {utils.getStaticLabel('add_item_modal_cancel_button', currentLanguage)}
                        </Button>

                        <Button onClick={addItem}>
                            {utils.getStaticLabel('add_item_modal_confirm_button', currentLanguage)}
                        </Button>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default Item;