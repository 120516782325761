const labels = {
    en: {
        menu_add_item_action_button: () => 'Add',
        cart_placeholder_title: () => 'Cart Empty',
        cart_placeholder_caption: () => 'All added items will appear here',
        cart_remove: () => 'remove',
        cart_confirm_selection_button: () => 'Confirm Order',
        add_item_modal_title: () => 'Add to Cart',
        add_item_modal_notes_title: () => 'Notes (optional)',
        add_item_modal_notes_placeholder: () => 'Additional Requests',
        add_item_modal_amount: () => 'Amount',
        add_item_modal_cancel_button: () => 'Cancel',
        add_item_modal_confirm_button: () => 'Add',
        checkout_back: () => 'Back to Menu',
        checkout_title: () => 'Checkout',
        checkout_tip: () => 'Tip',
        checkout_total: () => 'Total',
        checkout_tip_title: () => 'Tip (optional)',
        checkout_tip_placeholder: () => 'e.g.',
        checkout_location_title: () => 'Select your Position',
        checkout_location_placeholder: () => 'Select...',
        checkout_order_button: () => 'Order Now',
        order_confirmed_title: () => 'Order Confirmed',
        order_confirmed_caption: ({ orderNumber }) => `We have have received your order ${orderNumber} and will complete it shortly.`,
        last_order_section_title: () => 'Last Order',
        last_order_modal_title: () => 'Order',
        last_order_order_number: () => 'Order',
        last_order_view_button: () => 'View',
        last_order_summary_caption: ({ amount, total }) => `You ordered ${amount} items with a total of ${total}.`
    },
    it: {
        menu_add_item_action_button: () => 'Aggiungi',
        cart_placeholder_title: () => 'Carrello vuoto',
        cart_placeholder_caption: () => 'Tutti gli oggetti aggiunti appariranno qui',
        cart_remove: () => 'Rimuovi',
        cart_confirm_selection_button: () => 'Ordina ora',
        add_item_modal_title: () => 'Aggiungi al carrello',
        add_item_modal_notes_title: () => 'Appunti (opzionali)',
        add_item_modal_notes_placeholder: () => 'Richieste aggiuntive',
        add_item_modal_amount: () => 'Quantità',
        add_item_modal_cancel_button: () => 'Cancella',
        add_item_modal_confirm_button: () => 'Aggiungi',
        checkout_back: () => 'Torna al menu',
        checkout_title: () => 'Cassa',
        checkout_tip: () => 'Mancia',
        checkout_total: () => 'Totale',
        checkout_tip_title: () => 'Mancia (opzionale)',
        checkout_tip_placeholder: () => 'p.e.',
        checkout_location_title: () => 'Seleziona la tua pozicione',
        checkout_location_placeholder: () => 'Seleziona',
        checkout_order_button: () => 'Ordina adesso',
        order_confirmed_title: () => 'Ordine Confermato',
        order_confirmed_caption: ({ orderNumber }) => `Abbiamo ricevuto il tuo ordine #${orderNumber} e si completerà a breve.`,
        last_order_section_title: () => 'Ultimo ordine',
        last_order_modal_title: () => 'Ordine',
        last_order_order_number: () => 'Ordine',
        last_order_view_button: () => 'Visualizza',
        last_order_summary_caption: ({ amount, total }) => `Hai ordinato ${amount} articoli per un totale di ${total}.`
    },
    de: {
        menu_add_item_action_button: () => 'Auswählen',
        cart_placeholder_title: () => 'Einkaufswagen ist leer',
        cart_placeholder_caption: () => 'Ihre Auswahl erscheint hier',
        cart_remove: () => 'Entfernen',
        cart_confirm_selection_button: () => 'Bestätigen',
        add_item_modal_title: () => 'In den Einkaufswagen',
        add_item_modal_notes_title: () => 'Notizen (optional)',
        add_item_modal_notes_placeholder: () => 'Besondere Wünsche',
        add_item_modal_amount: () => 'Betrag',
        add_item_modal_cancel_button: () => 'Abbrechen',
        add_item_modal_confirm_button: () => 'Auswählen',
        checkout_back: () => 'Zurück zum Menü',
        checkout_title: () => 'Auswahl Bestätigen',
        checkout_tip: () => 'Trinkgeld',
        checkout_total: () => 'Gesamt',
        checkout_tip_title: () => 'Trinkgeld (optional)',
        checkout_tip_placeholder: () => 'z.B.',
        checkout_location_title: () => 'Wählen Sie Ihren Platz',
        checkout_location_placeholder: () => 'Auswählen',
        checkout_order_button: () => 'Jetzt Bestellen',
        order_confirmed_title: () => 'Bestellung Erhalten',
        order_confirmed_caption: ({ orderNumber }) => `Wir haben Ihre Bestellung #${orderNumber} erhalten und werden sie umgehend bearbeiten.`,
        last_order_section_title: () => 'Letzte Bestellung',
        last_order_modal_title: () => 'Bestellung',
        last_order_order_number: () => 'Bestellung',
        last_order_view_button: () => 'Öffnen',
        last_order_summary_caption: ({ amount, total }) => `Sie haben ${amount} Artikel mit einem Gesamtbetrag von ${total} bestellt.`
    },
    fr: {
        menu_add_item_action_button: () => 'Ajouter',
        cart_placeholder_title: () => 'Panier vide',
        cart_placeholder_caption: () => 'Tous les éléments ajoutés seront apparaissent ici',
        cart_remove: () => 'Enlever',
        cart_confirm_selection_button: () => 'Commander',
        add_item_modal_title: () => 'Ajouter au panier',
        add_item_modal_notes_title: () => 'Remarques (facultatif)',
        add_item_modal_notes_placeholder: () => 'Demandes supplémentaires',
        add_item_modal_amount: () => 'Quantité',
        add_item_modal_cancel_button: () => 'Annuler',
        add_item_modal_confirm_button: () => 'Ajouter',
        checkout_back: () => 'Retour au menu',
        checkout_title: () => 'Vérifier',
        checkout_tip: () => 'Bout',
        checkout_total: () => 'Le total',
        checkout_tip_title: () => 'Bout (facultatif)',
        checkout_tip_placeholder: () => 'Par exemple',
        checkout_location_title: () => 'Sélectionnez votre poste',
        checkout_location_placeholder: () => 'Choisir',
        checkout_order_button: () => 'Commandez maintenant',
        order_confirmed_title: () => 'Commande confirmée',
        order_confirmed_caption: ({ orderNumber }) => `Nous avons reçu votre commande #${orderNumber} et complètera il peu de temps.`,
        last_order_section_title: () => 'Dernière commande',
        last_order_modal_title: () => 'Commander',
        last_order_order_number: () => 'Commander',
        last_order_view_button: () => 'Voir',
        last_order_summary_caption: ({ amount, total }) => `Vous avez commandé ${amount} article pour un montant total de ${total}.`
    },
    es: {
        menu_add_item_action_button: () => 'Añadir',
        cart_placeholder_title: () => 'Carro de compra vacío',
        cart_placeholder_caption: () => 'Todos los articulos añadidos serán visibles aquí',
        cart_remove: () => 'Eliminar',
        cart_confirm_selection_button: () => 'Pedidos',
        add_item_modal_title: () => 'Añadir al carro',
        add_item_modal_notes_title: () => 'Notas (opcional)',
        add_item_modal_notes_placeholder: () => 'Peticiones adicionales',
        add_item_modal_amount: () => 'Cantidad',
        add_item_modal_cancel_button: () => 'Cancelar',
        add_item_modal_confirm_button: () => 'Añadir',
        checkout_back: () => 'Volver al menu',
        checkout_title: () => 'Caja',
        checkout_tip: () => 'Propina',
        checkout_total: () => 'Total',
        checkout_tip_title: () => 'Propina (opcional)',
        checkout_tip_placeholder: () => 'Por ejemplo;',
        checkout_location_title: () => 'Seleccione su posición',
        checkout_location_placeholder: () => 'Seleccione',
        checkout_order_button: () => 'Pedir ahora',
        order_confirmed_title: () => 'Pedido confirmado',
        order_confirmed_caption: ({ orderNumber }) => `Hemos recibido su pedido #${orderNumber} y lo completaremos en breve.`,
        last_order_section_title: () => 'Ultimo pedido',
        last_order_modal_title: () => 'Pedido',
        last_order_order_number: () => 'Pedido',
        last_order_view_button: () => 'Vista',
        last_order_summary_caption: ({ amount, total }) => `Ha pedido ${amount} artículos por un total de ${total}.`
    },
    ru: {
        menu_add_item_action_button: () => 'Добавить',
        cart_placeholder_title: () => 'Корзина пуста',
        cart_placeholder_caption: () => 'Здесь появятся все элементы добавления',
        cart_remove: () => 'Удалить',
        cart_confirm_selection_button: () => 'Заказать сейчас',
        add_item_modal_title: () => 'Добавить в корзину',
        add_item_modal_notes_title: () => 'Примечания (необязательно)',
        add_item_modal_notes_placeholder: () => 'Дополнительные запросы',
        add_item_modal_amount: () => 'Количество',
        add_item_modal_cancel_button: () => 'Отмена',
        add_item_modal_confirm_button: () => 'Добавить',
        checkout_back: () => 'Назад в меню',
        checkout_title: () => 'Проверить',
        checkout_tip: () => 'Чаевые',
        checkout_total: () => 'Общая',
        checkout_tip_title: () => 'Чаевые (необязательно)',
        checkout_tip_placeholder: () => 'Например',
        checkout_location_title: () => 'Выберите вашу позицию',
        checkout_location_placeholder: () => 'Выбрать',
        checkout_order_button: () => 'Заказать сейчас',
        order_confirmed_title: () => 'Заказ подтверждён',
        order_confirmed_caption: ({ orderNumber }) => `Мы получили ваш заказ №${orderNumber} и в ближайшее время его выполним.`,
        last_order_section_title: () => 'Последний заказ',
        last_order_modal_title: () => 'Заказать',
        last_order_order_number: () => 'Заказать',
        last_order_view_button: () => 'Просмотреть',
        last_order_summary_caption: ({ amount, total }) => `Вы заказали ${amount} товар на общую сумму ${total} евро.`
    },
    se: {
        menu_add_item_action_button: () => 'Lägg till',
        cart_placeholder_title: () => 'Varukorgen är tom',
        cart_placeholder_caption: () => 'Här kommer dina beställda varor att visas',
        cart_remove: () => 'Ta bort',
        cart_confirm_selection_button: () => 'Beställ nu',
        add_item_modal_title: () => 'Lägg till i varukorgen',
        add_item_modal_notes_title: () => 'Meddelande (valfritt)',
        add_item_modal_notes_placeholder: () => 'Övriga önskningar',
        add_item_modal_amount: () => 'Summa',
        add_item_modal_cancel_button: () => 'Avbryt',
        add_item_modal_confirm_button: () => 'Lägg till',
        checkout_back: () => 'Tillbaka till menyn',
        checkout_title: () => 'Kassa',
        checkout_tip: () => 'Dricks',
        checkout_total: () => 'Totalt',
        checkout_tip_title: () => 'Dricks (valfritt)',
        checkout_tip_placeholder: () => 'Till exempel',
        checkout_location_title: () => 'Välj din plats',
        checkout_location_placeholder: () => 'Välj',
        checkout_order_button: () => 'Beställ',
        order_confirmed_title: () => 'Din beställning är bekräftad',
        order_confirmed_caption: ({ orderNumber }) => `Vi har tagit emot din beställning #${orderNumber} och kommer att göra iordning den snarast.`,
        last_order_section_title: () => 'Senaste beställning',
        last_order_modal_title: () => 'Beställning',
        last_order_order_number: () => 'Beställning',
        last_order_view_button: () => 'Öppna',
        last_order_summary_caption: ({ amount, total }) => `Du beställde ${amount} artiklar för totalt ${total}.`
    },
    dk: {
        menu_add_item_action_button: () => 'Tilføj',
        cart_placeholder_title: () => 'Kurven er tom',
        cart_placeholder_caption: () => 'Alle tilføjede produkter fremstår her',
        cart_remove: () => 'Fjern',
        cart_confirm_selection_button: () => 'Bestil nu',
        add_item_modal_title: () => 'Tilføj til kurv',
        add_item_modal_notes_title: () => 'Meddelelse',
        add_item_modal_notes_placeholder: () => 'Øvrige ønsker',
        add_item_modal_amount: () => 'Beløb',
        add_item_modal_cancel_button: () => 'Afbyd',
        add_item_modal_confirm_button: () => 'Tilføj',
        checkout_back: () => 'Tilbage til menuen',
        checkout_title: () => 'Kassen',
        checkout_tip: () => 'Drikkepenge',
        checkout_total: () => 'Total',
        checkout_tip_title: () => 'Drikkepenge (valgfrit)',
        checkout_tip_placeholder: () => 'For eksempel',
        checkout_location_title: () => 'Vælg din position',
        checkout_location_placeholder: () => 'Vælg',
        checkout_order_button: () => 'Bestil nu',
        order_confirmed_title: () => 'Order bekræftigelse',
        order_confirmed_caption: ({ orderNumber }) => `Vi har modtage din order #${orderNumber} og vil fuldføre den snarest`,
        last_order_section_title: () => 'Sidste order',
        last_order_modal_title: () => 'Order',
        last_order_order_number: () => 'Order',
        last_order_view_button: () => 'Se',
        last_order_summary_caption: ({ amount, total }) => `Du har bestilt ${amount} varer til en samlet værdi af ${total}.`
    },
    al: {
        menu_add_item_action_button: () => 'Shto',
        cart_placeholder_title: () => 'Shporta është e zbrazet',
        cart_placeholder_caption: () => 'Zgjedhja juaj shfaqet këtu',
        cart_remove: () => 'Heq',
        cart_confirm_selection_button: () => 'Porosit tani',
        add_item_modal_title: () => 'Shto në Shportë',
        add_item_modal_notes_title: () => 'Shënime (opsionale)',
        add_item_modal_notes_placeholder: () => 'Kërkesa shtesë',
        add_item_modal_amount: () => 'Shuma',
        add_item_modal_cancel_button: () => 'Anulo',
        add_item_modal_confirm_button: () => 'Shto',
        checkout_back: () => 'Kthehu tek Menyja',
        checkout_title: () => 'Arkë',
        checkout_tip: () => 'Bakshish',
        checkout_total: () => 'Total',
        checkout_tip_title: () => 'Bakshish (opsionale)',
        checkout_tip_placeholder: () => 'p.sh.',
        checkout_location_title: () => 'Zgjidhni pozicionin tuaj',
        checkout_location_placeholder: () => 'Zgjidhni',
        checkout_order_button: () => 'Porosit Tani',
        order_confirmed_title: () => 'Porosia u konfirmua',
        order_confirmed_caption: ({ orderNumber }) => `Ne kemi marrë porosinë e juaj #${orderNumber} të cilën do ta përpunojmë se shpejti`,
        last_order_section_title: () => 'Porosia e fundit',
        last_order_modal_title: () => 'Porosi',
        last_order_order_number: () => 'Porosi',
        last_order_view_button: () => 'Hape',
        last_order_summary_caption: ({ amount, total }) => `Ju keni porositur ${amount} artikull me një total prej ${total}.`
    }
};

export default labels;
























