import React, { useState, useRef } from 'react';
import { IoLanguage } from 'react-icons/io5';

import { useOnClickOutside } from 'hooks';

import iDo from 'assets/logos/ido-grey.svg';
import AL from 'assets/flags/AL.svg';
import DE from 'assets/flags/DE.svg';
import DK from 'assets/flags/DK.svg';
import ES from 'assets/flags/ES.svg';
import FR from 'assets/flags/FR.svg';
import GB from 'assets/flags/GB.svg';
import IT from 'assets/flags/IT.svg';
import RU from 'assets/flags/RU.svg';
import SE from 'assets/flags/SE.svg';

import { useStore } from 'context';
import config from 'config';

import styling from './Footer.module.scss';

const Footer = () => {
    // State
    const [translationsShown, setTranslationsShown] = useState(false);


    // Store
    const [{ currentLanguage }, dispatch] = useStore();


    // Refs
    const languagesRef = useRef({});


    // Hooks
    useOnClickOutside(languagesRef, () => setTranslationsShown(false));


    /**
     * Saves the selected language in the global store.
     * @param language {string} the code of the language that was selected
     */
    const changeLanguage = (language) => {
        setTranslationsShown(false);
        dispatch({ type: 'update', payload: { currentLanguage: language } });
    };


    return (
        <footer className={styling.footer}>
            <div className={styling.container}>
                <div className={styling.language}>
                    <button className={styling.button} onClick={() => setTranslationsShown(true)}>
                        <div className={styling.icon}>
                            <IoLanguage/>
                        </div>

                        <span>
                            {config.translationOptions.find(x => x.value === currentLanguage)?.label}
                        </span>
                    </button>

                    <div className={styling.translations} ref={languagesRef} hidden={!translationsShown}>
                        <div onClick={() => changeLanguage('al')}>
                            <img src={AL} alt="albania"/>
                            <span>Albanian</span>
                        </div>

                        <div onClick={() => changeLanguage('de')}>
                            <img src={DE} alt="germany"/>
                            <span>German</span>
                        </div>

                        <div onClick={() => changeLanguage('dk')}>
                            <img src={DK} alt="denmark"/>
                            <span>Danish</span>
                        </div>

                        <div onClick={() => changeLanguage('es')}>
                            <img src={ES} alt="spain"/>
                            <span>Spanish</span>
                        </div>

                        <div onClick={() => changeLanguage('fr')}>
                            <img src={FR} alt="france"/>
                            <span>French</span>
                        </div>

                        <div onClick={() => changeLanguage('en')}>
                            <img src={GB} alt="great britain"/>
                            <span>English</span>
                        </div>

                        <div onClick={() => changeLanguage('it')}>
                            <img src={IT} alt="italy"/>
                            <span>Italian</span>
                        </div>

                        <div onClick={() => changeLanguage('ru')}>
                            <img src={RU} alt="russia"/>
                            <span>Russian</span>
                        </div>

                        <div onClick={() => changeLanguage('se')}>
                            <img src={SE} alt="sweden"/>
                            <span>Swedish</span>
                        </div>
                    </div>
                </div>

                <div className={styling.notice}>
                    <img src={iDo} alt="logo"/>

                    <a href="https://i-do.io" target="_blank" rel="noreferrer noopener">iDo - ordering system</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;