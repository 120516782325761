import React, { useRef, useCallback } from 'react';
import { IoBagHandleOutline } from 'react-icons/io5';
import { withRouter } from 'react-router-dom';

import { useOnClickOutside } from 'hooks';
import { useStore } from 'context';
import config from 'config';
import utils from 'utils';

import fallbackLogo from 'assets/logos/ido.svg';

import styling from './Nav.module.scss';

const Nav = ({ history }) => {
    // Store
    const [store, dispatch] = useStore();


    // Refs
    const menuRef = useRef({});


    /**
     * Changes the visibility of the settings menu
     * according to the provided boolean.
     * @type {(function(*=): void)|*}
     */
    const changeMenuVisibility = useCallback((open) => {
        dispatch({ type: 'update', payload: { settingsMenuOpen: open } });
    }, [dispatch]);


    /**
     * Removes the item with the given ID.
     * @param e {object} event object
     * @param id {number} the ID of the item to remove
     */
    const removeItem = (e, id) => {
        if ((store?.cart?.[id]?.amount || 0) - 1 <= 0) {
            e.target.parentNode.style.transform = 'translateX(2rem)';
            e.target.parentNode.style.opacity = '0';

            setTimeout(() => {
                dispatch({ type: 'removeItem', payload: id });
            }, 400);

        } else {
            dispatch({ type: 'removeItem', payload: id });
        }
    };


    // Close the settings menu if clicked outside
    useOnClickOutside(menuRef, () => changeMenuVisibility(false));


    // Aggregate shopping cart
    const cart = Object.values(store?.cart || {});


    // Determine total items in cart
    const amount = Object.values(store?.cart || {}).reduce((a, c) => a + c.amount, 0);


    // Determine currency symbol
    const currencySymbol = config.currencies.find(x => x.value === store?.currency)?.symbol || '';


    // Determine checkout page URL
    const checkoutUrl = history.location.pathname + '/checkout';


    // Is checkout
    const isCheckout = history.location.pathname.includes('/checkout');


    return (
        <nav className={styling.nav}>
            <div className={styling.container}>
                <img src={store?.logo || fallbackLogo} className={styling.logo} alt="logo"/>

                <button
                    className={styling.cart}
                    onClick={() => dispatch({ type: 'update', payload: { settingsMenuOpen: true } })}
                    hidden={isCheckout}
                >
                    <IoBagHandleOutline/>

                    <div className={styling.count} hidden={!amount}>
                        {amount}
                    </div>
                </button>

                <div className={styling.menu} ref={menuRef} hidden={!store.settingsMenuOpen || isCheckout}>
                    <div className={styling.placeholder} hidden={!!amount}>
                        <div className={styling.icon}>
                            <IoBagHandleOutline/>
                        </div>

                        <div className={styling.title}>
                            {utils.getStaticLabel('cart_placeholder_title', store.currentLanguage)}
                        </div>

                        <p>
                            {utils.getStaticLabel('cart_placeholder_caption', store.currentLanguage)}
                        </p>
                    </div>

                    <ul className={styling.items}>
                        {cart.map((item) => (
                            <li className={styling.item} key={item.id}>
                                <div className={styling.amount}>{item.amount}x</div>

                                <div className={styling.information}>
                                    <div>{item.name}</div>
                                    <div className={styling.price}>{item.price + ' ' + currencySymbol || '0.00 €'}</div>
                                </div>

                                <button className={styling.action} onClick={(e) => removeItem(e, item.id)}>
                                    {utils.getStaticLabel('cart_remove', store.currentLanguage)}
                                </button>
                            </li>
                        ))}
                    </ul>

                    <div hidden={!amount}>
                        <button className={styling.checkout} onClick={() => history.push(checkoutUrl)}>
                            {utils.getStaticLabel('cart_confirm_selection_button', store.currentLanguage)}
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default withRouter(Nav);