import React from 'react';
import Select, { components } from 'react-select';

import Flags from 'components/UI/flags/Flags';

import config from 'config';
import utils from 'utils';

/**
 * Overrides the provided theme.
 * @param theme {object} the current theme
 * @returns {object} overwritten theme
 * @private
 */
const _overwriteTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: utils.getCSSVariable('--custom-color-1'),
        primary25: utils.getCSSVariable('--color-2-contrast'),
        neutral05: utils.getCSSVariable('--color-2-dimmed'),
        neutral10: utils.getCSSVariable('--color-2-contrast'),
        neutral20: utils.getCSSVariable('--color-2-contrast'),
        neutral30: utils.getCSSVariable('--color-2-contrast'),
        neutral50: utils.getCSSVariable('--color-2-dark'),
        neutral60: utils.getCSSVariable('--color-2-contrast'),
        neutral70: utils.getCSSVariable('--color-2-contrast'),
        neutral80: utils.getCSSVariable('--custom-color-1'),
        neutral90: utils.getCSSVariable('--color-2-contrast')
    }
});

/**
 * Regular dropdown.
 * @param options {array} dropdown options
 * @param changeHandler {function} change handler
 * @param defaultValue {object} default value
 * @param value {object} selected value
 * @param placeholder {string} placeholder
 * @param searchable {boolean} determines whether the dropdown is searchable or not
 * @param disable {boolean} determines whether the dropdown is disabled or not
 * @returns {JSX.Element}
 */
const Dropdown = ({ options, changeHandler, defaultValue, value, placeholder, searchable, disabled }) => (
    <Select
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={changeHandler}
        placeholder={placeholder || 'Select...'}
        theme={_overwriteTheme}
        isSearchable={!!searchable}
        isDisabled={disabled}
    />
);

/**
 * Adds the respective flag to the selected value.
 * @param children {JSX.Element} children
 * @param props {object} component props
 * @returns {JSX.Element}
 * @constructor
 */
const SelectedTranslation = ({ children, ...props }) => (
    <components.Control {...props}>
        <Flags code={props?.selectProps?.value?.value} addLeftMargin/> {children}
    </components.Control>
);

/**
 * Adds the respective flag to the option.
 * @param children {JSX.Element} children
 * @param props {object} component props
 * @returns {JSX.Element}
 * @constructor
 */
const TranslationOption = ({ children, ...props }) => (
    <components.Option {...props}>
        <Flags code={props?.data?.value}/> {children}
    </components.Option>
);

/**
 * Dropdown with available translations.
 * @param changeHandler {function} change handler
 * @param value {object} selected value
 * @param placeholder {string} placeholder
 * @returns {JSX.Element}
 * @constructor
 */
const TranslationsDropdown = ({ changeHandler, value, placeholder }) => (
    <Select
        options={config.translationOptions}
        value={value}
        onChange={changeHandler}
        placeholder={placeholder || 'Select...'}
        components={{ Control: SelectedTranslation, Option: TranslationOption }}
        theme={theme => _overwriteTheme(theme)}
    />
);

export { TranslationsDropdown };
export default Dropdown;