import React, { useEffect } from 'react';
import { IoQrCodeOutline } from 'react-icons/io5';

import { useStore } from 'context/';

import logo from 'assets/logos/ido.svg';

import styling from './NotFound.module.scss';

const NotFound = () => {
    // Context
    const { 1: dispatch } = useStore();


    /**
     * Sets isLoading to false with a short delay.
     */
    useEffect(() => {
        setTimeout(() => {
            dispatch({ type: 'update', payload: { isLoading: false } });
        }, 250);
    }, [dispatch]);


    return (
        <main>
            <div className={styling.wrapper}>
                <div className={styling.icon}>
                    <IoQrCodeOutline/>
                </div>

                <h1>Scan QR Code</h1>

                <p>Please scan the QR to access the menu and place your order</p>

                <hr/>

                <img src={logo} alt="logo"/>

                <a href="https://i-do.io">Learn more about iDo</a>
            </div>
        </main>
    );
};

export default NotFound;