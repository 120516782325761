import React, { createContext, useContext, useReducer } from 'react';

import utils from 'utils';

const Context = createContext({});

const initialState = {
    name: '',
    items: [],
    positions: [],
    preselectedPositionId: '',
    cart: {},
    logo: '',
    coverImage: '',
    currency: '',
    currentLanguage: 'en',
    settingsMenuOpen: false,
    isLoading: true
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };


        case 'addItem': {
            const updatedCart = utils.copy(state.cart);

            if (!updatedCart[action.payload.id]) {
                updatedCart[action.payload.id] = { ...action.payload };
            } else {
                ++updatedCart[action.payload.id].amount;
            }

            return { ...state, cart: updatedCart };
        }


        case 'removeItem': {
            const updatedCart = utils.copy(state.cart);

            --updatedCart[action.payload].amount;

            if (updatedCart[action.payload].amount <= 0) {
                delete updatedCart[action.payload];
            }

            return { ...state, cart: updatedCart };
        }


        case 'clearCart': {
            return { ...state, cart: {} };
        }


        default:
            return state;
    }
};

export const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={[store, dispatch]}>
            {children}
        </Context.Provider>
    );
};

export const useStore = () => useContext(Context);