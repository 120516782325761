import fastCopy from 'fast-copy';

import labels from 'labels';

/**
 * Returns a promise that resolves after a given time.
 * @param time {number} time in milliseconds after which the promise should resolve
 * @returns {Promise<unknown>}
 */
const sleep = async (time = 50) => new Promise(resolve => setTimeout(resolve, time));

/**
 * Deep copies a given object.
 * @param obj {object} object that should be copied
 * @returns {*} a deep copy of the object that was provided
 */
const copy = (obj) => fastCopy(obj);

/**
 * Capitalizes the first character of a given string.
 * @param string {string} the string, whose first letter should be capitalized
 * @returns {string}
 */
const capitalize = (string = '') => {
    return string[0].toUpperCase() + string.slice(1);
};

/**
 * Returns the value for the given CSS variable name.
 * @param variableName {string} name of the CSS variable
 * @returns {string} the value for the given CSS variable
 */
const getCSSVariable = (variableName) => {
    return getComputedStyle(document.body).getPropertyValue(variableName);
};

/**
 * Returns the translation of the menu item for the desired key and language
 * or the English translation, if the translation in the desired
 * language is not available.
 * @param labels {object} object that contains the translations for each language
 * @param key {string} key that should be translated
 * @param language {string} the desired language code
 * @returns {*|string} translation for the desired label
 */
const getTranslation = (labels, key, language) => {
    return labels?.[language]?.[key] || labels?.en?.[key] || '';
};

const getStaticLabel = (id, language = 'en', dynamicContent) => {
    return labels[language]?.[id]?.(dynamicContent) || labels.en?.[id]?.(dynamicContent) || '';
};

const service = {
    sleep,
    copy,
    capitalize,
    getCSSVariable,
    getTranslation,
    getStaticLabel
};

export default service;