import React from 'react';
import { withRouter } from 'react-router-dom';

import styling from './PreviewNotice.module.scss';

const PreviewNotice = ({ location }) => (
    <div className={styling.banner} hidden={!location.pathname.includes('/preview/')}>
        <div><b>Preview Mode</b></div>

        <p>
            You are in preview mode. You can't use this page to receive orders from your guests. To receive orders, please open <a
            href="https://app.i-do.io/locations">https://app.i-do.io/locations</a> and create a new location. You can then assign this menu
            to your location and generate a QR code through which your guests can place orders.
        </p>
    </div>
);

export default withRouter(PreviewNotice);