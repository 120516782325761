import React, { useState, useEffect, useCallback } from 'react';

import LastOrder from './lastOrder/LastOrder';
import Category from './elements/Category';
import Item from './elements/Item';

import { useStore } from 'context/';
import utils from 'utils/';
import api from 'api/';

import styling from './Menu.module.scss';

const Menu = ({ match }) => {
    // States
    const [activeTab, setActiveTab] = useState(0);


    // Context
    const [{ name, coverImage, items, currentLanguage, isLoading }, dispatch] = useStore();


    /**
     * Fetches the menu data for the current ID.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchMenu = useCallback(async () => {
        try {
            if (name) {
                return;
            }

            let res;

            if (match.params.id) {
                res = await api.getMenuById(match.params.id);
            } else {
                res = await api.getMenuByUrl(match.params.organizationSlug, match.params.locationSlug);
            }

            document.documentElement.style.setProperty('--custom-color-1', res.menu?.color?.hex || '#000');

            const urlParams = new URLSearchParams(window.location.search);
            const preselectedPositionId = urlParams.get('positionId') || '';

            dispatch({
                type: 'update',
                payload: {
                    name: res.menu?.name || '',
                    items: res.menu?.items || [],
                    positions: res?.location?.positions || [],
                    logo: res.menu?.logo || '',
                    coverImage: res.menu?.coverImage || '',
                    currency: res.menu?.currency || '',
                    currentLanguage: (navigator.language || 'en').split('-')[0],
                    preselectedPositionId,
                    isLoading: false
                }
            });

        } catch (error) {
            console.error(error.message);
        }
    }, [match.params.id, match.params.organizationSlug, match.params.locationSlug, name, dispatch]);


    /**
     * Fetches the menu data when the menu ID
     * or the fetchMenu function changes.
     */
    useEffect(() => {
        fetchMenu();
    }, [fetchMenu]);


    return (
        <main>
            <div
                className={coverImage ? styling.coverImage : ''}
                style={{ backgroundImage: `url(${coverImage})` }}
                hidden={!coverImage}
            />

            <div className={styling.container} hidden={isLoading}>
                <ul className={styling.categories}>
                    {(items || []).map((item, i) => (
                        <li className={activeTab === i ? styling.active : styling.tab} onClick={() => setActiveTab(i)} key={i}>
                            {utils.getTranslation(item.multiLanguageLabels, 'name', currentLanguage)}
                        </li>
                    ))}
                </ul>

                <div className={styling.wrapper}>
                    <LastOrder/>

                    <h1>{utils.getTranslation(items?.[activeTab]?.multiLanguageLabels, 'name', currentLanguage)}</h1>

                    <ul className={styling.menu}>
                        {((items?.[activeTab]?.items || []).map((item, i) => (
                            <li key={i}>
                                {(item.type === 'category') ? (
                                    <Category
                                        name={utils.getTranslation(item.multiLanguageLabels, 'name', currentLanguage)}
                                        description={utils.getTranslation(item.multiLanguageLabels, 'description', currentLanguage)}
                                    >
                                        <ul>
                                            {(item.items || []).map((item, i) => (
                                                <li key={i}>
                                                    <Item {...item} />
                                                </li>
                                            ))}
                                        </ul>
                                    </Category>
                                ) : <Item {...item} />}
                            </li>
                        )))}
                    </ul>
                </div>
            </div>
        </main>
    );
};

export default Menu;