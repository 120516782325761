import React from 'react';

import styling from './Elements.module.scss';

const Category = ({ name, description, children }) => (
    <div className={styling.category}>
        <h2>{name}</h2>

        <p>{description}</p>

        {children}
    </div>
);

export default Category;