import React from 'react';
import { IoInformation } from 'react-icons/io5';

import styling from './Box.module.scss';

const Box = ({ children, type = 'info', addTopMargin, addBottomMargin, hidden }) => {
    let style = styling[type];

    if (addTopMargin) {
        style += ' ' + styling.addTopMargin;
    }

    if (addBottomMargin) {
        style += ' ' + styling.addBottomMargin;
    }

    return (
        <div className={style} hidden={hidden}>
            <div className={styling.icon}>
                <IoInformation/>
            </div>

            <p>{children}</p>
        </div>
    );
};

export default Box;