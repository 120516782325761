import React, { useState } from 'react';
import { IoCloseCircle } from 'react-icons/io5';
import { withRouter } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

import Modal from 'components/UI/modal/Modal';

import { useStore } from 'context';
import utils from 'utils';

import styling from './LastOrder.module.scss';

const LastOrder = ({ match }) => {
    // State
    const [orderDetailsVisible, setOrderDetailsVisible] = useState(false);


    // Store
    const [store] = useStore();


    /**
     * Toggles the visibility of the order details modal.
     */
    const toggleOrderDetailsVisibility = () => {
        setOrderDetailsVisible(prevState => !prevState);
    };


    // Determine last order
    const lastOrders = localStorage.getItem('latestOrders');
    const lastOrder = JSON.parse(lastOrders || '[]')?.[0];

    if (!lastOrder || lastOrder.organizationSlug !== match.params.organizationSlug) {
        return null;
    }


    // Determine amount of ordered items
    const amount = lastOrder?.items?.length || 0;


    // Determine currency symbol
    const currencySymbol = lastOrder.currencySymbol || '';


    // Format total and tip
    const total = (lastOrder.total || 0).toFixed(2) + currencySymbol;
    const tip = (lastOrder.tip || 0).toFixed(2) + currencySymbol;


    return (
        <>
            <div className={styling.container} hidden={!lastOrder}>
                <div className={styling.title}>
                    {utils.getStaticLabel('last_order_section_title', store.currentLanguage)}
                </div>

                <div className={styling.order} onClick={toggleOrderDetailsVisibility}>
                    <div className={styling.wrapper}>
                        <div className={styling.orderNumber}>
                            {utils.getStaticLabel('last_order_order_number', store.currentLanguage)} #{lastOrder.number}
                        </div>

                        <p>
                            {utils.getStaticLabel('last_order_summary_caption', store.currentLanguage, { amount, total })}
                        </p>

                        <div className={styling.timestamp}>
                            <ReactTimeAgo date={new Date(lastOrder.createdAt)}/>
                        </div>
                    </div>

                    <button className={styling.button}>
                        {utils.getStaticLabel('last_order_view_button', store.currentLanguage)}
                    </button>
                </div>
            </div>

            <Modal open={orderDetailsVisible}>
                <div className={styling.header}>
                    <h1>{utils.getStaticLabel('last_order_modal_title', store.currentLanguage)} #{lastOrder.number}</h1>

                    <button className={styling.close} onClick={toggleOrderDetailsVisibility}>
                        <IoCloseCircle/>
                    </button>
                </div>

                <ul className={styling.summary}>
                    {(lastOrder.items || []).map((item, i) => (
                        <li className={styling.item} key={i}>
                            <div className={styling.amount}>{(item.amount || 0)}x</div>
                            <div className={styling.name}>{item.name}</div>
                            <div className={styling.price}>{item.price.toFixed(2)} {currencySymbol}</div>
                        </li>
                    ))}

                    <li className={styling.item}>
                        <div className={styling.name}>Tip</div>
                        <div className={styling.price}>{tip}</div>
                    </li>

                    <li>
                        <div className={styling.line}/>
                        <div className={styling.total}>Total: {total}</div>
                    </li>
                </ul>

                <p className={styling.note}>
                    We have received this order <ReactTimeAgo date={new Date(lastOrder.createdAt)}/>.
                </p>
            </Modal>
        </>
    );
};

export default withRouter(LastOrder);