import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';

import Dropdown from 'components/UI/dropdown/Dropdown';
import Spinner from 'components/UI/spinner/Spinner';
import Lottie from 'components/UI/lottie/Lottie';
import Input from 'components/UI/input/Input';
import Box from 'components/UI/box/Box';

import { useStore } from 'context';
import config from 'config';
import utils from 'utils';
import api from 'api';

import styling from './Checkout.module.scss';

const Checkout = ({ history, match, location }) => {
    // Context
    const [store, dispatch] = useStore();


    // State
    const [{ positionId, tip, orderNumber, isLoading }, setState] = useState({
        positionId: store?.preselectedPositionId || '',
        tip: '',
        orderNumber: '',
        isLoading: false
    });


    /**
     * Handles input field changes.
     * @param name {string} name of the input field that has changed
     * @param value {string} value that was entered
     */
    const changeHandler = ({ target: { name, value } }) => {
        if (name === 'tip' && !config.regex.price.test(value)) {
            return;
        }

        if (name === 'tip' && value === '.') {
            value = '0';
        }

        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Handles dropdown changes.
     * @param value {string} the selected value
     */
    const dropdownChangeHandler = ({ value }) => {
        setState(prevState => ({ ...prevState, positionId: value }));
    };


    /**
     * Submits the current order.
     * @returns {Promise<void>}
     */
    const submitOrder = async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));

            const orderedItems = Object.values(store.cart || {}).map(item => ({
                id: item.id,
                amount: item.amount,
                note: item.note || ''
            }));

            const { organizationSlug, locationSlug } = match.params;

            const order = await api.createOrder(organizationSlug, locationSlug, { orderedItems, positionId, tip });

            setTimeout(() => {
                setState(prevState => ({ ...prevState, orderNumber: order.number }));

                order.currencySymbol = config.currencies.find(x => x.value === store?.currency)?.symbol || '';
                order.organizationSlug = organizationSlug;
                order.locationSlug = locationSlug;

                localStorage.setItem('latestOrders', JSON.stringify([order]));

                dispatch({ type: 'clearCart' });

            }, 400);

        } catch (error) {
            console.error(error.message);
        }
    };


    // Redirect to the menu if the checkout
    // page has been opened directly.
    if (store.isLoading) {
        return <Redirect to={location.pathname.replace('/checkout', '')}/>;
    }


    // Determine cart
    const cart = Object.values(store?.cart || {}).map(item => ({
        ...item,
        total: (item.amount || 0) * item.price
    }));


    // Determine total
    const total = (cart.reduce((a, c) => a + c.total, 0) + +tip).toFixed(2);


    // Determine currency symbol
    const currencySymbol = config.currencies.find(x => x.value === store?.currency)?.symbol || '';


    // Determine menu URL
    const menuUrl = location.pathname.replace('/checkout', '');


    // Determine if preview mode
    const isPreview = match.path.includes('/preview/:id/');


    // Determine position options
    const positionOptions = (store.positions || []).map(p => ({ label: p.name || '', value: p._id }));

    const selectedPosition = positionId ? positionOptions.find(p => p.value === positionId) : null;


    // Checkout summary
    const checkout = (
        <>
            <h1>{utils.getStaticLabel('checkout_title', store.currentLanguage)}</h1>

            <ul className={styling.box}>
                {cart.map(item => (
                    <li className={styling.item} key={item.id}>
                        <div className={styling.amount}>{item.amount}x</div>

                        <div className={styling.name}>{item.name}</div>

                        <div className={styling.price}>{item.total.toFixed(2)} {currencySymbol}</div>
                    </li>
                ))}

                <li className={styling.item}>
                    <div className={styling.name}>
                        {utils.getStaticLabel('checkout_tip', store.currentLanguage)}
                    </div>

                    <div className={styling.price}>{(+tip).toFixed(2)} {currencySymbol}</div>
                </li>

                <li>
                    <div className={styling.line}/>

                    <div className={styling.total}>
                        {utils.getStaticLabel('checkout_total', store.currentLanguage)}: {total} {currencySymbol}
                    </div>
                </li>
            </ul>

            <div className={styling.tip}>
                <Input
                    value={tip}
                    name="tip"
                    onChange={changeHandler}
                    placeholder={utils.getStaticLabel('checkout_tip_placeholder', store.currentLanguage) + ' 2.50'}
                    label={utils.getStaticLabel('checkout_tip_title', store.currentLanguage)}
                />
            </div>

            <h6>{utils.getStaticLabel('checkout_location_title', store.currentLanguage)}</h6>
            <Dropdown
                options={positionOptions}
                value={selectedPosition}
                changeHandler={dropdownChangeHandler}
                placeholder={utils.getStaticLabel('checkout_location_placeholder', store.currentLanguage)}
            />

            <Box type="warning" hidden={!isPreview} addTopMargin>
                Orders can not be submitted in preview mode. Please go to <a
                href="https://app.i-do.io/locations">https://app.i-do.io/locations</a> and assign this menu to a location to receive a QR
                code through which your guests can place orders.
            </Box>

            <button
                className={styling.confirmButton}
                onClick={submitOrder}
                hidden={isLoading || isPreview}
                disabled={!positionId}
            >
                {utils.getStaticLabel('checkout_order_button', store.currentLanguage)}
            </button>

            <div className={styling.spinner}>
                <Spinner size="small" hidden={!isLoading}/>
            </div>
        </>
    );


    // Order confirmation
    const orderConfirmation = (
        <div className={styling.confirmation}>
            <div className={styling.tick}>
                <Lottie type="tick" autoplay={true} loop={false}/>
            </div>

            <h1>
                {utils.getStaticLabel('order_confirmed_title', store.currentLanguage)}
            </h1>

            <p>
                {utils.getStaticLabel('order_confirmed_caption', store.currentLanguage, { orderNumber })}
            </p>

            <button className={styling.button} onClick={() => history.push(menuUrl)}>Back to Menu</button>
        </div>
    );


    return (
        <main className={styling.container}>
            <button className={styling.backToMenu} onClick={() => history.push(menuUrl)}>
                <IoArrowBackCircle/>
                <span>{utils.getStaticLabel('checkout_back', store.currentLanguage)}</span>
            </button>

            {orderNumber ? orderConfirmation : checkout}
        </main>
    );
};

export default Checkout;