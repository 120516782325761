import config from 'config';

const _send = async (path, opts) => {
    const accessPass = localStorage.getItem('access-pass');

    const options = {
        ...opts,
        headers: {
            'Content-Type': 'application/json',
            ...(accessPass && { 'Authorization': 'Bearer ' + accessPass }),
            ...(opts.headers || {})
        },
        credentials: 'include'
    };

    const res = await fetch(config.connectivity.apiBaseUrl + path, options);

    const parsedResponse = await res.json();

    if (!parsedResponse.ok) {
        throw new Error(parsedResponse.message || 'failed to send request');
    }

    return parsedResponse.data || {};
};

const getMenuById = async (menuId) => {
    const path = 'api/menu/' + menuId;

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const getMenuByUrl = async (organizationSlug, locationSlug) => {
    const path = `api/menu/${organizationSlug}/${locationSlug}`;

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const createOrder = async (organizationSlug, locationSlug, payload) => {
    const path = `api/order/${organizationSlug}/${locationSlug}`;

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return _send(path, opts);
};

const service = {
    getMenuById,
    getMenuByUrl,
    createOrder
};

export default service;