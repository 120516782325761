import React from 'react';

import styling from './Input.module.scss';

const Input = (props) => {
    const updatedProps = {
        ...props,
        type: props.type || 'text',
        'aria-label': props.name || ''
    };

    delete updatedProps.test;
    delete updatedProps.isInvalid;
    delete updatedProps.larger;
    delete updatedProps.isTextArea;

    let className = props.larger ? styling.larger : styling.input;

    if ((props.test && props.value && !props.test.test(props.value)) || props.isInvalid) {
        className = className + ' ' + styling.invalid;
    }

    let inputField = <input className={className} {...updatedProps} />;

    if (props.isTextArea) {
        className += ' ' + styling.textArea;
        inputField = <textarea className={className} {...updatedProps} />;
    }

    return (
        <label className={styling.regularLabel}>
            {props.label}
            {inputField}
            <span className={styling.caption} hidden={!props.caption}>{props.caption}</span>
        </label>
    );
};

export default Input;