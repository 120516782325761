import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PreviewNotice from './components/UI/previewNotice/PreviewNotice';
import Checkout from './components/checkout/Checkout';
import NotFound from './components/notFound/NotFound';
import Spinner from './components/UI/spinner/Spinner';
import Footer from './components/layout/footer/Footer';
import Nav from './components/layout/nav/Nav';
import Menu from './components/menu/Menu';

import { useStore } from 'context';

const App = () => {
    const [store] = useStore();

    return (
        <>
            <header>
                <Nav/>
            </header>

            <Switch>
                <Route path="/preview/:id" component={Menu} exact/>
                <Route path="/preview/:id/checkout" component={Checkout} exact/>
                <Route path="/:organizationSlug/:locationSlug" component={Menu} exact/>
                <Route path="/:organizationSlug/:locationSlug/checkout" component={Checkout} exact/>
                <Route component={NotFound}/>
            </Switch>

            <Footer/>

            <PreviewNotice/>

            <Spinner size="covering" hidden={!store.isLoading}/>
        </>
    );
};

export default App;
